<div class="sidebar" data-color="blue">
    <div class="logo">
        <a class="simple-text logo-mini">
            <div class="logo-img">
                <img class="logo-img-margin-top" src="./assets/img/iconai.png"/>
            </div>
        </a>
        <a [routerLink]="'/investorai/dashboard'" class="simple-text logo-normal">
            {{'investorAI'|translate}}
        </a>

        <div class="navbar-minimize">
            <button
                    id="minimizeSidebar"
                    class="btn btn-simple btn-icon btn-neutral btn-round"
                    (click)="minimizeSidebar()">
                <i
                        class="fas fa-list-ul visible-on-sidebar-regular"
                ></i>
                <i
                        class="fas fa-bars visible-on-sidebar-mini"
                ></i>
            </button>
        </div>
    </div>
    <div class="sidebar-wrapper">
        <div class="user">
            <div class="photo"><img src="../assets/img/usericon.svg"/></div>
            <div class="info">
                <a
                        data-toggle="collapse"
                        href="#collapseExample"
                        (click)="isCollapsed = !isCollapsed; myFunc($event,undefined)"
                        [attr.aria-expanded]="!isCollapsed"
                        aria-controls="collapseExample">
                    <span> {{user.name}} <b class="caret"></b> </span>
                </a>

                <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
                    <ul class="nav">
                        <li>
                            <a href="javascript:void(0)" (click)="logout()">
                                <span class="sidebar-normal">Log out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ul class="nav">
            <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
                <!--If is a single link-->
                <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
                    <i class="now-ui-icons {{ menuitem.icontype }}"></i>

                    <p>{{ menuitem.title | translate | uppercase }}</p>
                </a>
                <!--If it have a submenu-->
                <a
                        data-toggle="collapse"
                        href="#{{ menuitem.collapse }}"
                        *ngIf="menuitem.type === 'sub'"
                        (click)="
            myFunc($event, menuitem);
            menuitem.isCollapsing === undefined &&
              (menuitem.isCollapsed = !menuitem.isCollapsed)
          "
                        [attr.aria-expanded]="!menuitem.isCollapsed"
                >
                    <i class="now-ui-icons {{ menuitem.icontype }}"></i>

                    <p>{{ menuitem.title | uppercase }}<b class="caret"></b></p>
                </a>

                <!--Display the submenu items-->
                <div
                        id="{{ menuitem.collapse }}"
                        class="collapse"
                        *ngIf="menuitem.type === 'sub'"
                        [ngbCollapse]="menuitem.isCollapsed"
                >
                    <ul class="nav">
                        <li
                                routerLinkActive="active"
                                *ngFor="let childitem of menuitem.children"
                        >
                            <a [routerLink]="[menuitem.path, childitem.path]">
                                <span class="sidebar-mini-icon">{{ childitem.ab }}</span>
                                <span class="sidebar-normal">{{ childitem.title | translate | uppercase }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</div>
