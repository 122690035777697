<nav
  class="navbar navbar-expand-lg navbar-transparent  navbar-absolute bg-primary "
>
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="#aaa">{{ getTitle() }}</a>
    </div>
    <button class="navbar-toggler" type="button" (click)="collapse()"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="!isCollapsed">
      <ul class="navbar-nav">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/dashboard']">
            <i class="now-ui-icons design_app"></i> Dashboard
          </a>
        </li>
      
       
      </ul>
    </div>
  </div>
</nav>
<div class="wrapper wrapper-full-page ">
  <div class="full-page section-image" filter-color="black">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
