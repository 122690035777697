import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/constants/language.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent{
  dataNow: Date = new Date();
  langs: string [] = [];

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.translate.addLangs([Language.english, Language.spanish]);
    this.langs = this.translate.getLangs();
  }
  changeLang(lang: string) {
    this.translate.use(lang);
  }
  selectLanguage(lang: string): void {
    this.translate.use(lang);
  }
}

