import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('access_token');
        if (token && !request.url.includes('api/login')) {
            request = request.clone({
                headers: new HttpHeaders({
                    'Auth': token,
                    'Authorization': 'Bearer ' + token,
                }),
            });
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError(
                err =>
                    new Observable<HttpEvent<any>>(observer => {
                        if (err instanceof HttpErrorResponse) {
                            const errResp = <HttpErrorResponse>err;
                            if (errResp.status === 401 || errResp.status === 403) {
                                localStorage.removeItem('access_token');
                                this.router.navigateByUrl('pages/login');
                            }
                        }
                        observer.error(err);
                        observer.complete();
                    })
            )
        );
    }
}
