import {Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: '/investorai/dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
           {
                path: 'investorai',
                loadChildren: () => import('./investorai/investorai.module').then(m => m.InvestoraiModule),
            },
        ],
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'pages',
                loadChildren: () => import('./pages/pages/pages.module').then(m => m.PagesModule),
            },
        ],
    },
];
