export const appConfigs = {
    actionAdd: 'create',
    actionUpdate: 'update',
    backgroundPosition: 1,
    bgColor: 'bg-white',
    classInvalid: 'is-invalid',
    classOpenNav: 'nav-open',
    classSidebarMini: 'sidebar-mini',
    column_default : 'id',
    consolidationFactor: 1,
    countryPhone: 'EC',
    coverPosition: 0,
    customGapParametersInit: {
        company: 1,
        initialInvestment: 1000,
        tradeError: 50,
        consolidationFactor: 1,
        numGapDays: 1,
    },
    dateFormatInput: 'yyyy-MM-ddTHH:mm',
    dateFormatShortInput: 'yyyy-MM-dd',
    dateInputFormat: 'YYYY-MM-DD',
    daysBetweenRelease: 100,
    defaultPage: 'dashboard',
    empty: '',
    entries: [
        { id: 15, label: '15' },
        { id: 40, label: '40' },
        { id: 70, label: '70' },
    ],
    escrow: 'ESCROW',
    evaluatorLink: 'link',
    evaluatorSubItem: 'sub',
    facebookFormatUrl: 'https://facebook.com/',
    iCrudInit: {
        'data': [],
        'links': null,
        'meta': {
            current_page: 1,
            from: 0,
            last_page: 0,
            links: [],
            path: '',
            per_page: 1,
            to: 0,
            total: 0,
        },
    },
    idParamsGeneric: '{id}',
    image: 'image',
    imagePlaceHolder: '/assets/img/placeholder.png',
    indicatorSize: 1024,
    initCompany: 1,
    initCountry: 'CANADA',
    instagramFormatUrl: 'https://instagram.com/',
    issuance: 'ISSUANCE',
    tradeData: 'trade-data',
    largeSize: 'xl',
    maxImages: 5,
    maxItemsCoverBackground: 2,
    maxItemsMedia: 6,
    maxPhones: 4,
    maxSizeFileImage: 3072,
    maxSizeFileImageMessage: 'maxSizeFileImageMessage',
    maxSizeFileVideo: 4096,
    maxSizeFileVideoMessage: 'maxSizeFileVideoMessage',
    maxStars: 5,
    maxVideos: 1,
    mediumSize: 'md',
    minHour: '00:00',
    minLengthFilter: 2,
    navBarTransparent: 'navbar-transparent',
    newIndex: -1,
    noImage: '/assets/img/no_image.jpg',
    oneProfit: 1,
    optionWarrant: 'OPTION-WARRANT',
    orientationAsc : 'asc',
    orientationDesc : 'desc',
    originalAmount: 1000,
    page_init: 0,
    page_none: -1,
    per_page: 15,
    phoneFormat: 'tel://',
    preferred: 'PREFERRED',
    price: 0.5,
    printHTML: {
        openHtml: '<html lang="en">',
        closeHtml: '</html>',
        openBody: '<body onload="window.print();"',
        closeBody: '</body>',
        openHead: '<head>',
        closeHead: '</head>',
        meta: '<meta name="viewport" content="width=10000, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">',
        style: '<style type="text/css">#fab-controls{display: none} @media print {@page { margin:1cm;} .volume-price-filter, #map { margin-top: 2.5cm; } #map { height: 65vh !important; } .container-volume { flex-direction: column; } .detail-volume, .map-volume {max-width: 100% !important;}} .page-break { display: block; page-break-before: avoid; } </style>',
        script: '<script>window.onfocus = () => {window.close();};</script>',
        openWin: 'top=0,left=0,height=100%,width=auto',
    },
    releasesNumber: 1,
    roleAdmin: 'admin',
    roleClient: 'user',
    roleProvider: 'provider',
    sharedAbilityInputs: 'EST_LT_HOLDERS_INV',
    starEmpty: 'fas fa-star text-gray',
    starFull: 'fas fa-star',
    timeDefaultZero: 'T00:00:00.000000Z',
    titleModalAdmin: 'editCreateAdmins',
    titleModalClient: 'editCreateClients',
    titleModalProvider: 'editCreateProviders',
    titlePageAdmin: 'listAdmins',
    titlePageClient: 'listClients',
    titlePageProvider: 'listProviders',
    toolbarEditorDefault: [
        ['bold', 'italic'],
        ['underline'],
        [{ heading: ['h1', 'h2', 'h3'] }],
        ['link'],
        ['text_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ],
    twoProfit: 2,
    typeFather: 'father',
    typeLink: 'link',
    userCodeBlocked: 423,
    yes: 'S',
    zeroItem: 0,
    mobileWidthTo: 576,
    tabletWidthTo: 990
};

