<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute bg-primary ">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-toggle">
                <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
                    <span class="navbar-toggler-bar bar1"></span>
                    <span class="navbar-toggler-bar bar2"></span>
                    <span class="navbar-toggler-bar bar3"></span>
                </button>
            </div>
            <div class="d-flex">
                <a class="navbar-brand" href="#">{{'dashboard' | translate}}</a>
                <span class="navbar-brand">/</span>
                <span class="navbar-brand">{{ getTitle() | translate }}</span>
            </div>
        </div>
        <button class="navbar-toggler" type="button" (click)="collapse()"
                [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="!isCollapsed">
            <div class="sub-tittle-text-align-right" >
                <span class="sub-tittle-header">{{'titleRight' | translate}}</span>
                <br>
                <span>{{'subTitleRight' | translate}} </span>
            </div>
        </div>
    </div>
</nav>
