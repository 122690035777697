import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {

  authUrl = environment.investorServices + '/login';
  options: any;

  constructor(private http: HttpClient) {
    this.options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    };
  }

  login(e: string, p: string) {
    return this.http.post(
      this.authUrl,
      {
        email: e,
        password: p,
      },
      this.options
    );
  }

  logout() {
    this.options.headers.Authorization =
      'Bearer ' + localStorage.getItem('access_token');
    return this.http.get(
      environment.investorServices + '/api/logout',
      this.options
    );
  }
}
