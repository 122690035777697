import { Component, HostListener } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Language} from './constants/language.enum';
import { ScreenSizesService } from './services/screen-sizes.service';
import {appConfigs} from './constants/initialize';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'app';

  constructor(private translate: TranslateService, private screenSizesService: ScreenSizesService) {
    this.translate.setDefaultLang(Language.english);
    this.translate.use(Language.english);
    this.screenSizesService.isMobile.next(this.isMobile());
    this.screenSizesService.isTablet.next(this.isTablet());
  }

  @HostListener('window:resize', ['$event'])
    onResize() {
      this.screenSizesService.isMobile.next(this.isMobile());
      this.screenSizesService.isTablet.next(this.isTablet());
    }

  isMobile() {
    return window.innerWidth <= appConfigs.mobileWidthTo
  }

  isTablet() {
    return window.innerWidth > appConfigs.mobileWidthTo && window.innerWidth <= appConfigs.tabletWidthTo;
  }
}
