<footer class="footer">
  <div class="container-fluid">
    <nav>
    
    </nav>
    <div class="copyright" id="copyright">
      <select #langSelect (change)="changeLang(langSelect.value)">
        <option *ngFor="let lang of langs" [value]="lang">
          {{ lang }}
        </option>
      </select>
      &copy; {{ dataNow | date: "yyyy" }},  {{'designedBy' | translate}}
      <a href="https://teamq.biz/" target="_blank">{{'teamQ' | translate}}</a>.
    </div>
  </div>
</footer>