import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../services/aut.service';
import {Router} from '@angular/router';
import {User} from '../../models/users.model';

const misc: any = {
    sidebar_mini_active: true,
};

export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    isCollapsed?: boolean;
    isCollapsing?: any;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

export const ROUTES: RouteInfo[] = [
    {
        path: '/investorai/dashboard',
        title: 'Report dashboard',
        type: 'link',
        icontype: 'media-1_album',
    },
    {
        path: '/investorai/insiders',
        title: 'Insiders',
        type: 'link',
        icontype: 'ui-1_calendar-60',
    },
    {
        path: '/investorai/rootinvestorai',
        title: 'Visualize',
        type: 'link',
        icontype: 'design_app',
    },
    {
        path: '/investorai/balance',
        title: 'Inventory Balance',
        type: 'link',
        icontype: 'education_paper',
    },
    {
        path: '/investorai/uploaddatainvestorai',
        title: 'Upload Data',
        type: 'link',
        icontype: 'media-1_album',
    },

    {
        path: '/investorai/custom-gap',
        title: 'Custom Gap',
        type: 'link',
        icontype: 'design_image',
    },
    {
        path: '/investorai/escrowcalculationinvestorai',
        title: 'Escrow Calculations',
        type: 'link',
        icontype: 'files_single-copy-04',
    },
    {
        path: '/investorai/market-trade-wavg',
        title: 'Market Trade WAVG Adj',
        type: 'link',
        icontype: 'files_single-copy-04',
    },
    {
        path: '/investorai/share-balance-forecast',
        title: 'Share Balance Forecast',
        type: 'link',
        icontype: 'files_box',
    },
    {
        path: '/investorai/inventory',
        title: 'Inventory Trade Data by Period',
        type: 'link',
        icontype: 'files_single-copy-04',
    },
    {
        path: '/investorai/profit',
        title: 'Profit Walls',
        type: 'link',
        icontype: 'business_money-coins',
    },
    {
        path: '/investorai',
        title: 'Admin',
        type: 'sub',
        icontype: 'design_bullet-list-67',
        collapse: 'admin',
        isCollapsed: true,
        children: [
            {path: 'clients', title: 'Client Branding', ab: 'CB'},
            {path: 'stock-exchange', title: 'Stock Exchange', ab: 'SE'},
        ],
    },

];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public isCollapsed = true;
    public user: User;

    constructor(
        private toastr: ToastrService,
        private authService: AuthService,
        private router: Router
    ) {
    }

    ngOnInit() {
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
        }
        this.menuItems = ROUTES.filter((menuItem) => menuItem);
    }

    sleep(milliseconds) {
        const start = new Date().getTime();
        for (let i = 0; i < 1e7; i++) {
            if (new Date().getTime() - start > milliseconds) {
                break;
            }
        }
    }

    myFunc(event, menuitem) {
        event.preventDefault();
        event.stopPropagation();
        this.sleep(10);
        if (menuitem.isCollapsing === undefined) {
            menuitem.isCollapsing = true;
            let element = event.target;
            while (
                element.getAttribute('data-toggle') !== 'collapse' &&
                element !== document.getElementsByTagName('html')[0]
                ) {
                element = element.parentNode;
            }
            element = element.parentNode.children[1];

            if (
                element.classList.contains('collapse') &&
                !element.classList.contains('show')
            ) {
                element.classList = 'before-collapsing';
                const style = element.scrollHeight;

                element.classList = 'collapsing';
                setTimeout(function () {
                    element.setAttribute('style', 'height:' + style + 'px');
                }, 1);
                setTimeout(function () {
                    element.classList = 'collapse show';
                    element.removeAttribute('style');
                    menuitem.isCollapsing = undefined;
                }, 350);
            } else {
                const style = element.scrollHeight;
                setTimeout(function () {
                    element.setAttribute('style', 'height:' + (style + 20) + 'px');
                }, 3);
                setTimeout(function () {
                    element.classList = 'collapsing';
                }, 3);
                setTimeout(function () {
                    element.removeAttribute('style');
                }, 20);
                setTimeout(function () {
                    element.classList = 'collapse';
                    menuitem.isCollapsing = undefined;
                }, 400);
            }
        }
    }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        } else {
            misc.sidebar_mini_active = false;
        }
        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;
        } else {
            body.classList.add('sidebar-mini');
            misc.sidebar_mini_active = true;
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    showSidebarMessage(message) {
        this.toastr.show(
            '<span class="now-ui-icons ui-1_bell-53"></span>',
            message,
            {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: 'alert alert-danger alert-with-icon',
                positionClass: 'toast-top-right',
            }
        );
    }

    logout() {
        this.authService.logout().subscribe();
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        this.router.navigateByUrl('/pages/login');
    }
}
