import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from './components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { AppRoutes } from './app.routing';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RequestInterceptor } from './interceptor/request.interceptor';
import {SharedModule} from './shared.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { IsNumberDirective } from './directives/validators/is-number.directive';

import {LOCALE_ID } from '@angular/core';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEn, 'en'); 
registerLocaleData(localeEs, 'es'); 

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, IsNumberDirective],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
    NgbModule,
    ToastrModule.forRoot(), // ToastrModule added
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxUiLoaderModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
      },
  }),
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptor,
    multi: true,
  }, 
  {provide: LOCALE_ID, useValue: 'en'},
  {provide: LOCALE_ID, useValue: 'es'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
