import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ScreenSizesService {

    isMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isTablet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

}
